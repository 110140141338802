export function navigate(url, saveLocation) {
  console.log(`QR scanned: ${url}`)

  if (!(url.includes('goedgrutter') || url.includes('localhost'))) {
    console.log('Not a valid URL, will not navigate.')
    return
  }

  if (saveLocation){
    console.log(`Setting previousUrl: ${window.location.href}`); 
    localStorage.setItem('previousUrl', window.location.href)
  }

  window.location.href = url
}
