import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import * as styles from './intervention.module.css'
import BarcodeReader from 'react-barcode-reader'
import { navigate } from '../functions/utils'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.handleScan = this.handleScan.bind(this)
  }

  handleScan(data) {
    navigate(data, false);
  }

  componentDidMount() {
    // We only need to navigate back to the previous url if it's provided in the previous page.
    const previousUrl = localStorage.getItem('previousUrl')

    if (!previousUrl) {
      return
    }

    // Navigate back to the previous url after 3 minutes.
    setTimeout(() => {
      console.log('Timer elapsed, now navigating back to where we came from.')
      window.location.href = previousUrl
    }, 180000)
  }

  render() {
    const post = get(this.props, 'data.contentfulIntervention')

    let image

    if (post.image != null) {
      image = (
        <img
          src={`https:${post.image.resize.src}`}
          alt={post.image.description}
        ></img>
      )
    }

    return (
      <>
        <BarcodeReader onScan={this.handleScan} />
        <Layout location={this.props.location}>
          <div className={styles.container}>
            <h1 className={styles.header}>{post.title}</h1>
            {image}
            <div className={styles.article}>
              <div
                className={styles.body}
                dangerouslySetInnerHTML={{
                  __html: post.body.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query InterventionBySlug($slug: String!) {
    contentfulIntervention(slug: { eq: $slug }) {
      slug
      title
      rawDate: publishDate
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
  }
`
